export class UniteCloudHelper {
    public static getBaseUrl(hostname: string) : string {
        switch (hostname) {
            case "test-rel.3shape.com":
                return "https://test-unite.3shape.com/app";
            case "test-rel.3shape.cn":
                return "https://test-unite.3shape.cn/app";
            case "staging-rel.3shape.com":
                return "https://staging-unite.3shape.com/app";
            case "staging-rel.3shape.cn":
                return "https://staging-unite.3shape.cn/app";
            case "rel.3shape.com":
                return "https://unite.3shape.com/app";
            case "rel.3shape.cn":
                return "https://unite.3shape.cn/app";
            }
        return "https://dev-unite.3shape.com/app-dev-latest";
    }

    public static formatId(entity: string, id: string) : string {
        return btoa(`${entity}\ng${id.replace(/-/g, "")}`);
    }

    public static getCompanyId(args: string) : string | null {
        const match = args.match(/^\?c=([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/);
        if (match) {
            return match[1];
        }
        return null;
    }
}   
