import {
  UrlMapping,
  UniteCloudPatientMapping,
  UniteCloudPatientFileMapping,
  UniteCloudPatientScanMapping,
  UniteCloudCaseMapping
} from "./url-mappings/index";

export class UrlMappingService {
  private mappings: UrlMapping[] = [
    new UniteCloudPatientFileMapping(),
    new UniteCloudPatientScanMapping(),
    new UniteCloudCaseMapping(),
    new UniteCloudPatientMapping(),
  ];

  public remapUrl(hostname: string, path: string, args: string): string | null {
    for (var mapping of this.mappings) {
      let newUrl = mapping.remapUrl(hostname, path, args);
      if (newUrl !== null) {
        return newUrl;
      }
    }
    return null;
  }
}
