import { UniteCloudHelper } from "./unitecloud-helper";
import { UrlMapping } from "./url-mapping";
import { v5 as uuidv5 } from 'uuid';

export class UniteCloudPatientScanMapping implements UrlMapping {


    public remapUrl(hostname: string, path: string, args: string) : string | null {
        const match = path.match(/^\/uc\/v0\/p\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})\/f\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}),([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/);
        const OID_NAMESPACE = '6ba7b812-9dad-11d1-80b4-00c04fd430c8';
        if (match) {
            const baseUrl = UniteCloudHelper.getBaseUrl(hostname);
            const patientId = UniteCloudHelper.formatId("Patient", match[1]);
            const scan0Id = match[2].replace(/-/g, "");
            const scan1Id = match[3].replace(/-/g, "");
            const combinedScanId = (scan0Id > scan1Id) ? (scan0Id + scan1Id) : (scan1Id + scan0Id)
            const finalScanId = UniteCloudHelper.formatId( "Asset", uuidv5(combinedScanId, OID_NAMESPACE));
            const companyId = UniteCloudHelper.getCompanyId(args);
            if (companyId)
            {
                return `${baseUrl}/patients/${patientId}/files/${finalScanId}?companyId=${companyId}`;
            }
            return `${baseUrl}/patients/${patientId}/files/${finalScanId}`;
        }
        return null;
    }
}
