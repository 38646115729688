import { UniteCloudHelper } from "./unitecloud-helper";
import { UrlMapping } from "./url-mapping";

export class UniteCloudPatientMapping implements UrlMapping {
    public remapUrl(hostname: string, path: string, args: string) : string | null {
        const match = path.match(/^\/uc\/v0\/p\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/);
        if (match) {
            const baseUrl = UniteCloudHelper.getBaseUrl(hostname);
            const id = UniteCloudHelper.formatId("Patient", match[1]);
            const companyId = UniteCloudHelper.getCompanyId(args);
            if (companyId)
            {
                return `${baseUrl}/patients/${id}/cases?companyId=${companyId}`;
            }
            return `${baseUrl}/patients/${id}/cases`;
        }
        return null;
    }
}
